$colorPrimary: #37A548;
$colorPrimarySecond: #01473D;

$colorBorder: #e5e7eb;
$colorBorder2: #D1D5DB;

$textColorGrey: #6B7280;
$textColorGrey2: #9CA3AF;
$textColorPlaceholder: #9CA3AF;

$backgroundGreyForWhite: #F9FAFB;
