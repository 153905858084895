.wrapper {
  min-height: 15vh;
  min-width:60vw;
  max-width: 60vw;
  padding:40px;
  position: relative;
  // overflow-y: auto;
}
.commentsContainer {
  max-height:40vh;
  overflow-y: auto;
}

.linkStyle{
  display: flex;
  justify-content: space-between;  
}
.modelStyle{
  height: 70vh;
}