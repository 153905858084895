.wrapper {
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 71, 61, 0.1);
}
.contentWrapper {
  background: #FFFFFF;
  border-radius: 8px;
}
.crossIcon{
  display: flex;
  justify-content: end;
  justify-items: end;
  padding: 0.8rem 0.8rem 0rem 0rem;
}
.crossIcon>img:hover{
  cursor: pointer;
}
