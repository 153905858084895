.wrapper {
  min-height: 15vh;
  min-width:60vw;
  max-width: 60vw;
  padding:40px;
  position: relative;
}
.commentsContainer {
  max-height:40vh;
  overflow-y: auto;
}