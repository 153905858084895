@import 'src/style/fonts.scss', 'src/style/global.scss';

.ProductsContainer {
  padding-bottom: 50px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
  }

  &__title {
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 700;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }
}