@import 'src/style/fonts.scss', 'src/style/global.scss';

.LoginForm {
  background-color: #fff;
  border-radius: 12px;
  max-width: 450px;
  width: 100%;
  padding: 30px;

  &__title {
    @include fontLargeBold;
    margin-bottom: 8px;
  }

  &__subTitle {
    @include fontLargeNormal;
    font-size: 14px;
    margin-bottom: 30px;
    color: #4B5563;

    &__link {
      color: $colorPrimarySecond;
    }
  }

  &__form {
    display: grid;
    grid-gap: 23px;
  }

  &__button {
    width: 100%;
    font-size: 16px;
  }

  &__logo {
    color: $colorPrimary;
    margin-bottom: 25px;
  }

  &__passwordRow {
    display: flex;
    justify-content: space-between;

    &__link {
      color: $colorPrimarySecond;
    }
  }
}