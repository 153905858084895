.wrapper {
  padding: 41px 47px 47px;
  @apply flex flex-col bg-white rounded-md shadow
}
.badge {
  border-radius: 10px;
  color: #01473D;
  margin-bottom:21px;
  @apply pt-0.5 px-2.5 bg-gray-100 w-max text-xs leading-4 font-medium
}
.desc {
  width: 100%;
  max-width:656px;
  margin-bottom:33px;
  @apply text-gray-400 font-normal text-base
}
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  & > li {
    padding-left: 32px;
    padding-right: 32px;
    flex-direction:column;
    border-right: 0.5px solid #D2D4D8;
  }
  & > li:last-of-type {
    border-right: none;
    padding-right: 0;
  }
  & > li:first-of-type {
    padding-left: 0;
  }
  & > li h3 {
    color: #1E293B;
    opacity: 0.5;
    margin-bottom:1px;
    @apply text-xs leading-4 font-medium tracking-wider uppercase;
  }
  & > li div {
    color: #1E293B;
    @apply text-base leading-6 font-normal
  }
}