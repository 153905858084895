@import 'src/style/fonts.scss' , 'src/style/global.scss';

.Button {
  border-radius: 6px;
  height: 38px;
  padding: 12px 18px;
  line-height: 1;
  @include fontMediumNormal;
  width: max-content;

  &--primary {
    background-color: $colorPrimary;
    color: #fff;
  }

  &--normal {
    border: 1px solid $colorBorder2;
    background-color: #fff;
    color: #374151;
    & svg {
      color: $textColorGrey;
    }
  }

  &--iconWithText {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 11px;
  }

  &__icon svg{
    width: 14px;
    height: 14px;
  }
  &[disabled]{
    background-color: #8c97a1e3;
    cursor: not-allowed;
    color: #fff;
  }
}