@import 'src/style/fonts.scss', 'src/style/global.scss';

.App {
  @include fontMedium;

  &__wrapper {
    background-color: #F3F4F6;
    min-height: calc(100vh - 64px);
  }

  &__container {
    width: 90vw;
    margin: 0 auto;
  }
}

.tabulator {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  border: 1px solid $colorBorder !important;
  margin: 0 !important;

  &-header {
    border-bottom: 1px solid $colorBorder !important;

    & .tabulator-col-title {
      @include fontMediumNormal;
      color: $textColorGrey;
      text-transform: uppercase;
    }
  }

  &-tableholder {
    & .tabulator-table {
      & .tabulator-row {
        border-bottom: none;

        & .tabulator-cell {
          @include fontMediumNormal;
          overflow: unset;
        }

        &-even {
          background-color: $backgroundGreyForWhite;

          &:hover {
            background-color: $backgroundGreyForWhite !important;
          }
        }

        &-odd {
          &:hover {
            background-color: #fff !important;
          }
        }
      }
    }
  }
}


.ReactModal {
  &__Overlay {
    background-color: #01473dcc !important;
  }

  &__Content {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px !important;
    border: none !important;
    width: max-content;
    min-width: 846px;
    height: max-content;
    min-height: 400px;
    margin: 0 auto;
    top: 50% !important;
    transform: translateY(-50%);
    display: grid;
    grid-template-rows: 1fr 63px;
    padding: 0 !important;
    position: relative !important;
    max-height: 90vh !important;
    overflow: hidden;
    max-width: 90vw;
    left: 0 !important;
  }

  &__controls {
    background-color: $backgroundGreyForWhite;
    align-self: end;
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border: 1px solid $colorBorder;
  }

  &__wrapper {
    height: max-content;
  }

  &__body {
    padding: 38px 45px 30px;
    @include fontLargeNormal;
    font-size: 14px;
    overflow-y: auto;
    height: auto;
  }

  &__title {
    @include fontLargeNormal;
    font-size: 24px;
    margin-bottom: 20px;
  }
}


.DragDropFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 208px;
  width: 100%;
  max-width: 100%;
  border: 2px dashed #D1D5DB;
  border-radius: 6px;

  &__body {
    display: grid;
    grid-gap: 4px;
    justify-items: center;

    &__title {
      color: $colorPrimarySecond;

      & span {
        color: #4B5563;
      }
    }

    &__subtitle {
      color: $textColorGrey;
      font-size: 12px;
    }
  }

  &__icon {
    margin-bottom: 8px;
    color: $textColorGrey2;
  }
}