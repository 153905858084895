.wrapper {
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 71, 61, 0.8);
}

.contentWrapper {
  background: #FFFFFF;
  border-radius: 8px;
  min-height: 600px;
  width:1040px;
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  position: relative;
  
}
.closeIcon{
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin:0.8rem 0.8rem 0rem 0rem;
}
.closeIcon:hover{
  cursor: pointer;
}
.formWrapperCss {
  display:flex;
  flex-direction: column;
  height: 100%;
  width:100%;
}