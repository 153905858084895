.activeBtn {
  @apply z-10 bg-green-50 border-green-500 text-green-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium
}
.defaultBtn {
  @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium
}
.certificatesWrapper {

  & > div:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  & > div:last-of-type {
    border-bottom: 1px solid #E5E7EB;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  & > div {
    padding: 13px 17px 12px 13px;
    border: 1px solid #E5E7EB;
    border-bottom: 0;
    display: flex;
    justify-content:space-between;
    color: #06134B;
    @apply text-sm leading-5 font-normal
  }
}
.certificatesModalWrap {
  height:50vh;
  width:35vw;
  min-width:800px;
  padding: 40px 20px;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
}
.certManufacturerName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:80px;
}

.manufacturerName {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manufacturerTooltipWrap {
  width: 200px;
}
.componentSelect {
  border-color: #e1e1e1;
  box-shadow: none;
  border-radius: 6px;
}
select:focus{
  box-shadow: none;
  border-color: #e1e1e1;
}


