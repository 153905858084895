@import 'src/style/fonts.scss', 'src/style/global.scss';

.Textarea {

  &__label {
    @include fontLargeNormal;
    color: #374151;
    font-size: 14px;
    padding-bottom: 4px;
  }

  & textarea {
    width: 100%;
    height: 93px;
    border: 1px solid $colorBorder2;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    @include fontMediumNormal;
  }

  & textarea::placeholder {
    @include fontMediumNormal;
    color: $textColorPlaceholder;
  }
}