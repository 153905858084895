@import 'src/style/fonts.scss', 'src/style/global.scss';


.LoginFormWrapper {
  display: flex;
  height: 100vh;

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
  }

  &__right {
    width: 50%;

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}