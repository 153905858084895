@import 'src/style/fonts.scss', 'src/style/global.scss';

.Products {
  &__tableLink {
    @include fontMediumNormal;
    color: $colorPrimarySecond;
  }

  &__collectionStatus {
    @include fontMediumNBold;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 10px;
    width: max-content;

    &--1 {
      background: #FFEDD5;
      color: #9A3412
    }

    &--2 {
      background: #BFDAFE;
      color: #1C4ED8
    }

    &--3 {
      background: #FFEDD5;
      color: #9A3412
    }
    &--4 {
      background: #D1FAE5;
      color: #065F46;
    }
  }
}