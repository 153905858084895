
@font-face {
  font-family: 'Circular-std-medium';
  src: url('fonts/circular-std-medium.ttf');
}

@font-face {
  font-family: 'Circular-std-light';
  src: url('./fonts/Circular-std-light.ttf');
}


@mixin fontMedium {
  font-size: 14px;
  font-family: 'Circular-std-light';
}
@mixin fontMediumNormal {
  @include fontMedium;
  font-weight: 400;
}
@mixin fontMediumNBold {
  @include fontMedium;
  font-weight: 600;
}



@mixin fontLarge {
  font-size: 32px;
  font-family: 'Circular-std-medium';
}

@mixin fontLargeNormal {
  @include fontLarge;
  font-weight: 400;
}

@mixin fontLargeBold {
  @include fontLarge;
  font-weight: 600;
}