@import 'src/style/fonts.scss', 'src/style/global.scss';

.Header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 7fr 1fr;
  align-items: center;
  grid-gap: 80px;
  background-color: #fff;
  height: 64px;

  &__wrapper {
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &__logo {
    color: $colorPrimary;
  }

  &__navItems {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 16px;
    height: 100%;
  }

  &__navItem {
    display: flex;
    align-items: center;
    color: $textColorGrey;
    padding: 0 4px;

    &--active {
      color: #111827;
      border-bottom: 2px solid $colorPrimary;
      margin-top: 2px;
    }
  }

  &__avatar {
    justify-self: flex-end;

    &__image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
  &__label {
    @include fontLargeNormal;
    color: #374151;
    font-size: 14px;
    padding-bottom: 4px;
  }
}