@import 'src/style/fonts.scss', 'src/style/global.scss';

.Input {
  position: relative;

  &__label {
    @include fontLargeNormal;
    color: #374151;
    font-size: 14px;
    padding-bottom: 4px;
  }

  &__body {
    position: relative;

    & input {
      @include fontMediumNormal;
      border: 1px solid $colorBorder2;
      border-radius: 6px;
      width: 100%;
      height: 38px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      &::placeholder {
        @include fontMediumNormal;
        color: $textColorPlaceholder;
      }
    }

    .Input--small {
      height: 38px;
    }

    .Input--large {
      height: 48px;
    }
  }

  &__errorIcon {
    position: absolute;

    &--small {
      top: 9px;
      right: 9px;
    }

    &--large {
      top: 13px;
      right: 12px;
    }
  }
}