@import 'src/style/fonts.scss', 'src/style/global.scss';

.SelectList {
  position: relative;

  &__label {
    @include fontLargeNormal;
    color: #374151;
    font-size: 14px;
    padding-bottom: 4px;
  }

  &__multiIcon {
    color: $textColorGrey2;
    margin-right: 15px;
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &__icon {
      width: 14px;
      color: #06134B;
    }

    &:hover {
      background-color: rgba(204, 204, 204, 0.3);
    }
  }
}